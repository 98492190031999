












































import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import FormElement from 'common-modules/src/components/CommonFormElement.vue';
import { Announcement } from '@/store/Announcement';
import JwlButton from '@/components/JwlButton.vue';
import JwlSubmitRow from '@/components/JwlSubmitRow.vue';

const STATUS_ERROR = -1;
const STATUS_NOTHING = 0;
const STATUS_SUBMITTING = 1;
const STATUS_SUCCESS = 2;

@Component({
  components: {
    JwlSubmitRow,
    JwlButton,
    FormElement,
  },
})
export default class JwlAnnouncementEdit extends Vue {
  $refs!: {
    announcementForm: HTMLFormElement;
  }

  @Prop(Number)
  classroomId!: number;

  @Prop(String)
  jwlCourseCode!: string;

  @Prop(Object)
  jwlAnnouncement!: Announcement;

  editor = ClassicEditor;
  editorConfig = {
    toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
    placeholder: this.$t('task.textPlaceholder'),
  };

  text = '';
  syncStatus = STATUS_NOTHING;
  error = null;

  submitAnnouncement (): void {
    if (this.syncStatus !== STATUS_SUBMITTING) {
      this.syncStatus = STATUS_SUBMITTING;
      const FD = new FormData(this.$refs.announcementForm);
      FD.append('announcementId', this.jwlAnnouncement.id);
      FD.append('text', this.text);
      FD.append('courseCode', this.jwlCourseCode);
      FD.append('classroomId', this.classroomId.toString());

      this.$store.dispatch('postData', {
        url: 'announcement',
        formData: FD,
      }).then((data) => {
        this.$emit('update-announcement', data);
        this.syncStatus = STATUS_SUCCESS;
      }).catch((e) => {
        this.error = e;
        this.syncStatus = STATUS_ERROR;
      });
    }
  }

  @Watch('jwlAnnouncement')
  adaptAnnouncement (): void {
    if (this.jwlAnnouncement) {
      this.text = this.jwlAnnouncement.text;
    }
  }

  created (): void {
    this.adaptAnnouncement();
  }
}
